import type { GetPopupAPIResponse } from '@onetext/api';

type PopupProps = {
    popup : GetPopupAPIResponse,
};

type RegisterPopupConfig = {
    externalID : string,
    Component : (props : PopupProps) => JSX.Element | null,
};

const popupRegistry = new Map<string, RegisterPopupConfig['Component']>();

export const registerPopup = (config : RegisterPopupConfig) : void => {
    popupRegistry.set(config.externalID, config.Component);
};

export const getPopupComponent = (externalID : string) : RegisterPopupConfig['Component'] | undefined => {
    return popupRegistry.get(externalID);
};

/** @jsx h */

import type { VNode } from 'preact';

import { createContext, h } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useState } from 'preact/hooks';

import { Z_INDEX_MAX } from '../constants';
import { isEditorMode } from '../lib';

const ALLOW_DIALOG = true as boolean;

type DialogContextType = {
    element : HTMLElement | undefined,
    closeDialog : () => void,
};

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const useDialogContext = () : DialogContextType | undefined => {
    return useContext(DialogContext);
};

type DialogProps = {
    children : VNode,
};

export const Dialog = ({
    children
} : DialogProps) : VNode => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ suppressDialog, setSuppressDialog ] = useState(false);
    const [ currentDialog, setCurrentDialog ] = useState<HTMLDialogElement | undefined>();

    if (
        isEditorMode() ||
        !ALLOW_DIALOG ||
        suppressDialog ||
        typeof HTMLDialogElement !== 'function' ||
        !('showModal' in HTMLDialogElement.prototype)
    ) {
        return children;
    }

    const closeDialog = useCallback(() : void => {
        // currentDialog?.close();
        setCurrentDialog(undefined);
        setSuppressDialog(true);
        setIsOpen(false);
    }, [ currentDialog ]);

    const dialogContext = useMemo(() => {
        return {
            element: currentDialog,
            closeDialog
        };
    }, [ currentDialog, closeDialog ]);

    useEffect(() => {
        return () => {
            if (currentDialog) {
                // currentDialog.close();
            }
        };
    }, [ currentDialog ]);

    return (
        <DialogContext.Provider value={ dialogContext }>
            <dialog
                style={
                    {
                        zIndex:     Z_INDEX_MAX,
                        border:     'none',
                        background: 'none'
                    }
                }
                ref={
                    el => {
                        setCurrentDialog(el ?? undefined);

                        if (isOpen) {
                            return;
                        }

                        if (typeof el?.showModal === 'function') {
                            try {
                                el.showModal();
                            } catch {
                                setSuppressDialog(true);
                            }

                            setIsOpen(true);
                        }
                    }
                }
            >
                { children }
            </dialog>
        </DialogContext.Provider>
    );
};

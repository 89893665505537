import type { ButtonColors, ButtonStyle } from '../../../../types';

export const buttonStyle : ButtonStyle = {
    borderWidth:  '1px',
    borderRadius: '4px'
};

export const buttonColors : ButtonColors = {
    primary: {
        text: {
            base:  '#ffffff',
            hover: '#ffffff'
        },
        background: {
            base:  '#000000',
            hover: '#151515'
        },
        border: {
            base:  'rgba(255,255,255,0.25)',
            hover: 'rgba(255, 255, 255, 0.5)'
        }
    },
    secondary: {
        text: {
            base:  'rgb(80,109,111)',
            hover: 'rgb(80,109,111)'
        },
        background: {
            base:  'rgba(165,208,201,0.25)',
            hover: 'rgba(165,208,201,0.4)'
        },
        border: {
            base:  'rgb(80,109,111)',
            hover: 'rgb(80,109,111)'
        }
    },
    tertiary: {
        text: {
            base:  '#ffffff',
            hover: '#ffffff'
        },
        background: {
            base:  'transparent',
            hover: 'transparent'
        },
        border: {
            base:  'transparent',
            hover: 'transparent'
        }
    }
};

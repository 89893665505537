import type { TeaserColors, TeaserStyle } from '../../../../types';

export const teaserStyle : TeaserStyle = {
    height:        '48px',
    borderRadius:  '4px',
    fontSize:      '18px',
    lineHeight:    '1em',
    fontWeight:    '700',
    padding:       '0 20px',
    border:        '2px solid #000',
    boxShadow:     '4px 4px 0px 0px #000000FF',
    letterSpacing: '1.5px'
};

export const teaserColors : TeaserColors = {
    text: {
        base:  '#000',
        hover: '#000'
    },
    background: {
        base:  '#FDAD71',
        hover: '#FDAD71'
    }
};

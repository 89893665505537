import type { BrandConfig } from '../../../../types';

import { buttonColors, buttonStyle } from './button';
import { brandColors } from './colors';
import { fieldStyle } from './field';
import { brandFont } from './font';
import { teaserColors, teaserStyle } from './teaser';

export const defaultBrandConfig : BrandConfig = {
    font:   brandFont,
    colors: brandColors,
    field:  {
        style: fieldStyle
    },
    button: {
        style:  buttonStyle,
        colors: buttonColors
    },
    teaser: {
        style:  teaserStyle,
        colors: teaserColors
    }
};

/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';

import { trackBuyerEvent } from '../../../biz';
import {
    ConsentQuizContext, useConsentFormContext, useConsentPopupContext, useConsentPopupPageContext, useConsentQuizContext
} from '../context';

import { ConsentPopupForm } from './form';

import { BUYER_EVENT } from '@onetext/api';

type ConsentPopupQuizProps = {
    children ?: ComponentChildren,
    propertyName : string,
};

export const ConsentPopupQuiz = ({
    children,
    propertyName
} : ConsentPopupQuizProps) : VNode => {
    const {
        popup,
        setProperties
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentFormContext();

    const submitQuizOption = useCallback(async ({
        propertyValue
    } : {
        propertyValue : string,
    }) => {
        setProperties(existingProperties => {
            return {
                ...existingProperties,
                [ propertyName ]: propertyValue
            };
        });

        trackBuyerEvent({
            buyerEvent: BUYER_EVENT.POPUP_QUIZ_RESULT,
            payload:    {
                popupToken: popup.token,
                propertyName,
                propertyValue
            }
        });

        await submit();
    }, [ submit, propertyName, setProperties ]);

    const quizContext = useMemo(() => {
        return {
            submitQuizOption
        };
    }, [ submitQuizOption ]);

    return (
        <ConsentQuizContext.Provider value={ quizContext }>
            { children }
        </ConsentQuizContext.Provider>
    );
};

type ConsentPopupQuizOptionProps = {
    propertyValue : string,
    children ?: ComponentChildren,
};

export const ConsentPopupQuizOption = ({
    propertyValue,
    children
} : ConsentPopupQuizOptionProps) : VNode => {
    const {
        submitQuizOption
    } = useConsentQuizContext();

    const {
        openKeyboardOnSubmit
    } = useConsentPopupPageContext();

    const submitQuiz = useCallback(() => {
        return submitQuizOption({
            propertyValue
        });
    }, [ submitQuizOption, propertyValue ]);

    return (
        <ConsentPopupForm
            onSubmit={ submitQuiz }
            openKeyboardOnSubmit={ openKeyboardOnSubmit }
        >
            { children }
        </ConsentPopupForm>
    );
};

/** @jsx h */
import type { VNode } from 'preact';

import clsx from 'clsx';
import { h } from 'preact';

import { assertUnreachable, isEditorMode, MODAL_TYPE, track } from '../../../lib';
import { CLOSE_BUTTON_SIZE } from '../../../types';
import { useConsentPopupContext } from '../context';

import type { TOKEN, TokenType } from '@onetext/api';

const logPopupClose = (accountToken : TokenType<TOKEN.ACCOUNT> | undefined) : void => {
    track('consent_popup_close_button_click', {
        accountToken
    });
};

const logTeaserClose = (accountToken : TokenType<TOKEN.ACCOUNT> | undefined) : void => {
    track('consent_teaser_close_button_click', {
        accountToken
    });
};

type CloseButtonProps = {
    delay ?: boolean,
    className ?: string,
    size ?: CLOSE_BUTTON_SIZE,
    animate ?: boolean,
    type : MODAL_TYPE,
};

export const CloseButton = ({
    delay = true,
    className,
    size = CLOSE_BUTTON_SIZE.LG,
    animate = true,
    type
} : CloseButtonProps) : VNode => {
    const { closePopup, closeTeaser, accountToken } = useConsentPopupContext();

    const handleClose = (event : Event) : void => {
        event.stopPropagation();

        if (isEditorMode()) {
            return;
        }

        switch (type) {
            case MODAL_TYPE.POPUP:
                logPopupClose(accountToken);
                closePopup();
                break;
            case MODAL_TYPE.TEASER:
                logTeaserClose(accountToken);
                closeTeaser();
                break;
            default:
                throw assertUnreachable(type);
        }
    };

    const buttonSize = {
        [CLOSE_BUTTON_SIZE.XS]: 'w-3 h-3',
        [CLOSE_BUTTON_SIZE.SM]: 'w-4 h-4',
        [CLOSE_BUTTON_SIZE.MD]: 'w-5 h-5',
        [CLOSE_BUTTON_SIZE.LG]: 'w-6 h-6'
    }[size];

    const svgSize = {
        [CLOSE_BUTTON_SIZE.XS]: '12',
        [CLOSE_BUTTON_SIZE.SM]: '16',
        [CLOSE_BUTTON_SIZE.MD]: '20',
        [CLOSE_BUTTON_SIZE.LG]: '25'
    }[size];

    const strokeWidth = {
        [CLOSE_BUTTON_SIZE.XS]: '2.5',
        [CLOSE_BUTTON_SIZE.SM]: '1.75',
        [CLOSE_BUTTON_SIZE.MD]: '1.3',
        [CLOSE_BUTTON_SIZE.LG]: '1.1'
    }[size];

    return (
        <button
            type={ 'button' }
            onClick={ handleClose }
            className={
                clsx(
                    'fixed top-5 right-5',
                    'cursor-pointer',
                    'focus:outline-none',
                    'z-50',
                    buttonSize,
                    animate && 'fade-in-up',
                    animate && (delay
                        ? 'animation-delay-[0.75s]'
                        : 'animation-delay-[0s]'),
                    className
                )
            }
            aria-label={ 'Close offer' }
        >
            <svg
                width={ svgSize }
                height={ svgSize }
                viewBox={ '0 0 25 25' }
                fill={ 'none' }
                xmlns={ 'http://www.w3.org/2000/svg' }>
                <path
                    d={ 'M2.97266 2.97168L22.1799 22.1789' }
                    stroke={ 'currentColor' }
                    stroke-width={ strokeWidth } />
                <path
                    d={ 'M22.1799 2.97168L2.97266 22.1789' }
                    stroke={ 'currentColor' }
                    stroke-width={ strokeWidth } />
            </svg>
        </button>
    );
};

/** @jsx onetext.jsx.element */
import type { OneText } from '../../global';

import { getPopupComponent } from './registry';
import { PopupTemplate } from './template';

import type { GetPopupAPIResponse } from '@onetext/api';

declare let onetext : OneText;

type RenderPopupOptions = {
    popup : GetPopupAPIResponse,
    containerElement ?: HTMLElement,
};

export const renderPopup = async ({
    popup,
    containerElement = document.body
} : RenderPopupOptions) : Promise<void> => {
    const { render } = onetext.jsx;

    const { externalID } = popup;

    if (externalID) {
        const PopupComponent = getPopupComponent(externalID);

        if (!PopupComponent) {
            throw new Error(`No popup component registered for externalID: ${ externalID }`);
        }

        return render({
            element: (
                <PopupComponent popup={ popup } />
            ),
            container: containerElement,
            shadowDOM: true
        });
    }

    return render({
        element: (
            <PopupTemplate popup={ popup } />
        ),
        container: containerElement,
        shadowDOM: true
    });
};

/** @jsx onetext.jsx.element */

import type { OneText } from '../../../global';

import { defaultBrandConfig } from './config';

import type { GetPopupAPIResponse } from '@onetext/api';

declare let onetext : OneText;

type PopupTemplateProps = {
    popup : GetPopupAPIResponse,
};

export const PopupTemplate = ({ popup } : PopupTemplateProps) : JSX.Element | null => {
    const { clsx } = onetext.jsx;

    const {
        Popup,
        Page,
        EmailField,
        PhoneField,
        Button,
        Row,
        Column,
        Terms,
        PopupContainer,
        Teaser,
        POPUP_LAYOUT
    } = onetext.component.consent.popup;

    return (
        <PopupContainer
            popup={ popup }
            brandConfig={ defaultBrandConfig }>
            <Popup
                layout={ POPUP_LAYOUT.FULLSCREEN }
                modalClassName={
                    clsx(
                        'md:rounded-[24px] overflow-hidden',
                        'h-full md:min-w-[440px]'
                    )
                }>
                <Page openKeyboardOnSubmit>
                    <Row className={ 'px-4 mt-12 max-w-xl mx-auto' }>
                        <Column>
                            <div className={ 'text-center space-y-5 font-medium' }>
                                <h1 className={
                                    clsx(
                                        'text-5xl md:text-[54px]',
                                        'leading-[1.1em]',
                                        'text-center'
                                    )
                                }>
                                    { 'Get 15% Off Today' }
                                </h1>
                                <p className={ 'text-xl font-thin max-w-sm mx-auto' }>
                                    { 'Sign up for email and SMS to unlock your discount!' }
                                </p>
                            </div>
                            <div className={
                                clsx(
                                    'max-w-sm w-full',
                                    'flex',
                                    'flex-col',
                                    'items-center',
                                    'gap-2.5 mt-8'
                                )
                            }>
                                <EmailField
                                    placeholder={ 'email address' }
                                    autofocus={ true }
                                />
                                <Button>
                                    { 'Continue' }
                                </Button>
                            </div>
                        </Column>
                    </Row>
                </Page>

                <Page openKeyboardOnSubmit>
                    <Row className={ 'px-4 mt-12' }>
                        <Column>
                            <Column className={ 'space-y-5 text-center mb-8' }>
                                <h1 className={
                                    clsx(
                                        'text-5xl md:text-[54px]',
                                        'max-w-2xl !leading-[1.1]',
                                        'font-base',
                                        'text-center'
                                    )
                                }>
                                    { 'You\'re so close!' }
                                </h1>
                                <p className={
                                    clsx(
                                        'text-2xl max-w-sm md:max-w-lg',
                                        'font-light',
                                        'leading-relaxed font-base',
                                        'text-center',
                                        'mb-6'
                                    )
                                }>
                                    { 'Join our VIP text club to unlock exclusive offers!' }
                                </p>
                                <div className={
                                    clsx(
                                        'max-w-sm w-full',
                                        'flex',
                                        'flex-col',
                                        'items-center',
                                        'gap-2.5'
                                    )
                                }>
                                    <PhoneField
                                        placeholder={ 'phone number' }
                                        autofocus={ true }
                                    />
                                    <Button>
                                        { 'Activate 15% Off' }
                                    </Button>
                                </div>
                            </Column>
                            <Terms
                                brandName={ 'Brand Name' }
                                termsUrl={ 'https://example.com/terms' }
                                privacyUrl={ 'https://example.com/privacy' }
                            />
                        </Column>
                    </Row>
                </Page>

                <Page>
                    <Row className={ 'px-4 mt-12' }>
                        <Column>
                            <Column className={ 'space-y-4 text-center mb-8 md:mb-12' }>
                                <h1 className={
                                    clsx(
                                        'text-5xl md:text-[54px]',
                                        'max-w-2xl',
                                        'font-base',
                                        'text-center'
                                    )
                                }>
                                    { 'Did you get it?' }
                                </h1>
                                <p className={
                                    clsx(
                                        'text-xl max-w-sm md:max-w-lg',
                                        'font-light',
                                        'leading-relaxed font-base',
                                        'text-center'
                                    )
                                }>
                                    { 'Check your texts to make sure you are in!' }
                                </p>
                            </Column>
                            <Button className={ 'max-w-sm w-full' }>
                                { 'Back To Site' }
                            </Button>
                        </Column>
                    </Row>
                </Page>
            </Popup>
            <Teaser customText={ '15% Off' } />
        </PopupContainer>
    );
};

/** @jsx h */

import type { VNode } from 'preact';

import clsx from 'clsx';
import { h } from 'preact';

type ConsentPopupTermsProps = {
    brandName : string,
    termsUrl : string,
    privacyUrl : string,
    className ?: string,
};

export const ConsentPopupTerms = ({
    brandName,
    termsUrl,
    privacyUrl,
    className
} : ConsentPopupTermsProps) : VNode => {
    return (
        <p className={
            clsx(
                'text-[9px] md:text-[10px]',
                'font-light',
                'leading-relaxed',
                'text-center',
                'w-[300px] md:w-[320px]',
                'opacity-80',
                className
            )
        }>
            {
                'By entering your number, you agree to receive ' +
                `automated marketing texts from ${ brandName } and OneText. ` +
                'No purchase required. ' +
                'Reply HELP for help; ' +
                'STOP to opt out. View '
            }
            <a
                href={ termsUrl }
                target={ '_blank' }
                rel={ 'noopener noreferrer' }
                className={ 'underline' }>{ 'Terms' }
            </a>
            { ' & ' }
            <a
                href={ privacyUrl }
                target={ '_blank' }
                rel={ 'noopener noreferrer' }
                className={ 'underline' }>{ 'Privacy' }
            </a>
        </p>
    );
};
